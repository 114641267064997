import { Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser';

export interface ScriptLoaderScript {
  src: string;
  inlineContent?: string;
}

export type TScriptTarget = HTMLHeadElement | HTMLBodyElement;

@Injectable()
export class ScriptLoaderService {
  scripts = new Map<string, Promise<ScriptLoaderScript>>();

  constructor(private windowRef: WindowRef) {}

  async load(
    src: string,
    target: TScriptTarget = this.windowRef.documentHead,
    inlineContent?: string,
  ): Promise<ScriptLoaderScript> {
    let promise = this.scripts.get(src);
    if (!promise) {
      promise = new Promise<ScriptLoaderScript>((resolve, reject) => {
        let script = this.windowRef.document.createElement('script');
        script.id = src;
        script.type = 'text/javascript';
        if (inlineContent) {
          script.innerHTML = inlineContent;
        } else {
          script.src = src;
        }
        script.onload = () => {
          return resolve({ src, inlineContent });
        };
        script.onerror = () => {
          reject({ src, inlineContent });
          this.scripts.delete(src);
        };
        target.appendChild(script);
      });
      this.scripts.set(src, promise);
    }
    return promise;
  }

  unload(src: string): boolean {
    const script = this.windowRef.document.getElementById(src);
    if (script && script instanceof HTMLScriptElement) {
      // Set event listeners to null to remove them
      script.onload = null;
      script.onerror = null;

      // Remove the script element from the document
      script.remove();

      this.scripts.delete(src);
      return true;
    }
    return false;
  }
}
