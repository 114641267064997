import { InjectionToken } from '@angular/core';
import { IMentionMeModuleConfig } from '@x/common/referral/mention-me/mention-me.config';

export interface IReferralModuleConfig {
  mentionMe?: IMentionMeModuleConfig;
}

export type TReferralKey = keyof IReferralModuleConfig;

export const REFERRAL_MODULE_CONFIG = new InjectionToken<IReferralModuleConfig | undefined>(
  'REFERRAL_MODULE_CONFIG',
);
