import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Params } from '@angular/router';
import { WindowRef } from '@x/common/browser';
import { TMentionMeEndpoint } from '@x/common/referral/mention-me/mention-me.config';
import { IReferralModuleConfig, REFERRAL_MODULE_CONFIG } from '@x/common/referral/referral.config';
import { ScriptLoaderService, TScriptTarget } from '@x/common/script-loader/script-loader.service';
import { objectToUrlParams } from '@x/common/utils';

@Injectable()
export class MentionMeService {
  constructor(
    private scriptLoader: ScriptLoaderService,
    private windowRef: WindowRef,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(REFERRAL_MODULE_CONFIG) private config: IReferralModuleConfig | undefined,
  ) {}

  get mentionMeConfig() {
    return this.config?.mentionMe;
  }

  get isConfigured() {
    return this.mentionMeConfig !== undefined;
  }

  loadScript(
    endpoint: TMentionMeEndpoint,
    params: Params,
    target: TScriptTarget = this.windowRef.documentBody,
  ) {
    const locale = this.locale.replace('-', '_');

    params = { ...params, locale };

    if (!this.mentionMeConfig) return Promise.reject('Mention me not configured');

    const src = `${this.mentionMeConfig.api}/${endpoint}/${
      this.mentionMeConfig.partnerCode
    }?${objectToUrlParams(params)}`;

    if (this.scriptLoader.scripts.has(src)) {
      return Promise.resolve({ src });
    }

    return this.scriptLoader.load(src, target);
  }

  unloadScript(src: string, key: string) {
    const mentionMeFiredTags = this.windowRef.get('MentionMeFiredTags');
    if (mentionMeFiredTags && mentionMeFiredTags[key]) {
      delete mentionMeFiredTags[key];

      this.scriptLoader.unload(src);
    }
  }
}
